import { sparkBaseURL } from "@Ignite-Reading/ui-kit/env";
import { addWeeks, formatISO, subWeeks } from "date-fns";

export function todayISO() {
  return formatISO(new Date(), { representation: "date" });
}

export function oneWeekFromTodayISO() {
  return formatISO(addWeeks(new Date(), 1), { representation: "date" });
}

export function oneWeekAgoFromTodayISO() {
  return formatISO(subWeeks(new Date(), 1), { representation: "date" });
}

export const generateTutorEditLink = tutorUuid => {
  return `${sparkBaseURL}/admin/tutors/${tutorUuid}/edit`;
};

export const generateStudentLATLink = studentUuid => {
  return `${sparkBaseURL}/students/${studentUuid}`;
};

export const generateStudentEditLink = studentUuid => {
  return `${sparkBaseURL}/admin/students/${studentUuid}/edit`;
};
