import { Table } from "@Ignite-Reading/ui-kit/components";
import PropTypes from "prop-types";

import PresencePill, {
  JOINED,
  NOT_PRESENT,
  LEFT,
} from "components/PresencePill";
import SessionDetailsDialog from "components/SessionDetailsDialog";
import { generateStudentEditLink, generateTutorEditLink } from "utils";

const PastSessionItem = ({
  admin,
  date,
  school,
  schoolSystem,
  startTime,
  student,
  studentStatus,
  studentUuid,
  tutor,
  tutorStatus,
  tutorUuid,
  userSessionOverrideClaimed,
  uuid,
}) => (
  <Table.Row>
    <Table.Cell>
      <div className="space-y-0.5">
        <ul className="flex flex-wrap">
          {admin ? (
            <li className="p-1">
              <PresencePill
                data-testid="admin-pill"
                isSessionMonitor
                status={JOINED}>
                {admin}
              </PresencePill>
            </li>
          ) : null}
          <li className="p-1">
            <PresencePill
              data-testid="tutor-pill"
              hideStatusDot={!tutor}
              href={tutorUuid ? generateTutorEditLink(tutorUuid) : null}
              status={tutorStatus}
              isSub={userSessionOverrideClaimed}>
              {tutor}
            </PresencePill>
          </li>
          <li className="p-1">
            <PresencePill
              data-testid="student-pill"
              hideStatusDot
              href={generateStudentEditLink(studentUuid)}
              status={studentStatus}>
              {student}
            </PresencePill>
          </li>
        </ul>
        <SessionDetailsDialog.Trigger
          sessionIsInPast
          title="Past session"
          uuid={uuid}
        />
      </div>
    </Table.Cell>
    <Table.Cell>
      {school}
      <br />
      <span className="text-zinc-400">{schoolSystem}</span>
    </Table.Cell>
    <Table.Cell className="sm:pr-6">
      <div className="flex flex-col items-end space-y-0.5">
        <p
          className="text-xs text-zinc-700 font-semibold whitespace-nowrap"
          data-testid="past-session-date">
          {date}
        </p>
        <p
          className="text-xs text-zinc-700"
          data-testid="past-session-startTime">
          {startTime}
        </p>
      </div>
    </Table.Cell>
  </Table.Row>
);

PastSessionItem.propTypes = {
  admin: PropTypes.string,
  date: PropTypes.string,
  school: PropTypes.string,
  schoolSystem: PropTypes.string,
  startTime: PropTypes.string,
  student: PropTypes.string,
  studentStatus: PropTypes.oneOf([LEFT, NOT_PRESENT, JOINED]),
  studentUuid: PropTypes.string,
  tutor: PropTypes.string,
  tutorStatus: PropTypes.oneOf([LEFT, NOT_PRESENT, JOINED]),
  tutorUuid: PropTypes.string,
  userSessionOverrideClaimed: PropTypes.bool.isRequired,
  uuid: PropTypes.string.isRequired,
};

export default PastSessionItem;
