export const BASE_10 = 10;
export const SEARCH_INPUT_DEBOUNCE_DELAY = 400;

export const END_DATE_SEARCH_PARAM = "end_date";
export const LIVE_SESSIONS_PAGE_SEARCH_PARAM = "ls_page";
export const LIVE_SESSIONS_PER_PAGE = 8;
export const PAGE_SEARCH_PARAM = "page";
export const SEARCH_TERM_SEARCH_PARAM = "search";
export const START_DATE_SEARCH_PARAM = "start_date";
export const UPCOMING_SESSIONS_PAGE_SEARCH_PARAM = "us_page";
export const UPCOMING_SESSIONS_PER_PAGE = 8;

export const PollingStatus = {
  CONNECTING: "connecting",
  OPEN: "open",
  CLOSED: "closed",
};
