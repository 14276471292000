import { Button, DateRangePicker } from "@Ignite-Reading/ui-kit/components";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { END_DATE_SEARCH_PARAM, START_DATE_SEARCH_PARAM } from "constants";

const FilterByDate = ({ defaultEndDate = "", defaultStartDate = "" }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [endDate, setEndDate] = useState(
    searchParams.get(END_DATE_SEARCH_PARAM) || defaultEndDate
  );
  const [startDate, setStartDate] = useState(
    searchParams.get(START_DATE_SEARCH_PARAM) || defaultStartDate
  );
  /*
   * We performe two distinct operations when dates change because we don't
   * want to rely solely on the URL state (`location.search`). Waiting for the
   * search params to update is slow, which in turn makes the date picker feel
   * sluggish to the user.
   *
   * On the other hand, we avoid updating the URL search params via side-
   * effects (using `useEffect`) because it's also slightly slower (set state
   * -> re-render -> run effect -> update url), requires multiple checks in the
   * `useEffect` implementation to prevent unnecessary runs (such as during the
   * initial render) and we can't batch-update search params.
   */
  const onChangeEndDate = useCallback(
    value => {
      const nextEndDate = value === "" ? defaultEndDate : value;

      setEndDate(nextEndDate);
      setSearchParams(prev => ({
        ...Object.fromEntries(prev.entries()),
        [END_DATE_SEARCH_PARAM]: nextEndDate,
      }));
    },
    [defaultEndDate, setSearchParams]
  );
  const onChangeStartDate = useCallback(
    value => {
      const nextStartDate = value === "" ? defaultStartDate : value;

      setStartDate(nextStartDate);
      setSearchParams(prev => ({
        ...Object.fromEntries(prev.entries()),
        [START_DATE_SEARCH_PARAM]: nextStartDate,
      }));
    },
    [defaultStartDate, setSearchParams]
  );
  const onResetDates = useCallback(() => {
    setEndDate(defaultEndDate);
    setStartDate(defaultStartDate);
    setSearchParams(prev => {
      prev.delete(END_DATE_SEARCH_PARAM);
      prev.delete(START_DATE_SEARCH_PARAM);

      return Object.fromEntries(prev.entries());
    });
  }, [defaultEndDate, defaultStartDate, setSearchParams]);

  return (
    <div className="flex items-center space-x-3">
      <span className="text-gray-500 text-sm">Filter by date:</span>
      <div className="flex items-center space-x-3">
        <DateRangePicker
          endDate={endDate}
          onChangeEndDate={onChangeEndDate}
          onChangeStartDate={onChangeStartDate}
          startDate={startDate}
        />
        <Button
          disabled={
            startDate === defaultStartDate && endDate === defaultEndDate
          }
          onClick={onResetDates}
          variant={Button.SECONDARY}>
          Reset
        </Button>
      </div>
    </div>
  );
};

FilterByDate.propTypes = {
  defaultEndDate: PropTypes.string,
  defaultStartDate: PropTypes.string,
};

export default FilterByDate;
